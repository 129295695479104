<template>

	<el-select clearable filterable v-model="value" :placeholder="$t('FormMsg.Please_select')" :size="size"
		:style="{width: width+'px'}" @change="change">
		<el-option v-for="(item,index) in list" :key="index" :label="item.companyName" :value="item.userId">
			<div class="sel_option_s1" style="">
				<span class="sel_option_name">
					<span v-if="!!item.skuPrefix">
						<span>{{item.skuPrefix}}</span>
						<span class="sel_option_name_sub">
							({{ item.companyName }})
							<el-tag size="mini" v-if="'1'==item.isSub">
								子账户
							</el-tag>
							<!-- <el-tag size="mini" type="info" plain>
								{{item.account}}
							</el-tag> -->
						</span>
					</span>
					<span v-else>
						{{ item.companyName }}
						<el-tag size="mini" v-if="'1'==item.isSub">
							子账户
						</el-tag>
						<!-- <el-tag size="mini" type="info" plain>
							{{item.account}}
						</el-tag> -->
					</span>
				</span>
				<span class="sel_option_code" style="">
					<span>{{ item.cusNo }}</span>
				</span>
			</div>
		</el-option>
	</el-select>

</template>
<script>
	export default {

		props: {
			width: {
				default: function() {
					return 150
				},
				type: Number
			},
			size: {
				default: function() {
					return 'small'
				},
				type: String
			},
		},
		data() {
			return {

				value: "",
				list: [],
				loading: false,

			};
		},
		// watch: {
		//   value: function(val1, val2) {
		//     console.log("watch cas value",arguments);
		//     // this.getListData_1();
		//     // this.init();
		//   }
		//   // initLinkageData: function(val1, val2) {
		//   //   // this.getListData_1();
		//   //   this.init();
		//   // }
		// },
		//创建时
		created() {
			this.init();
		},
		//编译挂载前
		mounted() {
			// console.log("mounted");
		},
		methods: {
			init() {
				// this.props = Object.assign(this.myProps, this.props)
				this.getListData();

			},
			initData(val) {
				if (val) {
					this.value = val;
				} else {
					this.value = '';
				}
			},

			//值改变
			change(v) {
				console.log('change', v);
				this.$emit('change', v);
			},

			//信息
			getListData() {
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhProxyUserList, {})
					.then(({
						data
					}) => {
						console.log(data);
						this.loading = false;
						if (200 == data.code) {
							let list = data.rows;
							this.list = data.rows;
							//       this.list = list.filter(item=>{
							// 	return !!item.cusWhName
							// });
						} else {

						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log(this.$t('i18nn_758239e08104dfb8'));
					});
				// }
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>